import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

import './scss/app.scss';

jQuery(document).ready(function(){

	// Slider Vredesvrouwen

	$('.vredesvrouwen').slick({
	  dots: false,
	  infinite: true,
	  speed: 300,
	  slidesToShow: 4,
	  slidesToScroll: 4,
	  prevArrow: '.arrow-prev',
	  nextArrow: '.arrow-next',
	  responsive: [
	    {
	      breakpoint: 1200,
	      settings: {
	        slidesToShow: 3,
	        slidesToScroll: 3,
	        infinite: true,
	        dots: false
	      }
	    },
	    {
	      breakpoint: 992,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 2
	      }
	    },
	    {
	      breakpoint: 768,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1
	      }
	    }

	  ]
	});

	// Slider Publicaties

	$('.publicaties').slick({
	  dots: false,
	  infinite: true,
	  speed: 300,
	  slidesToShow: 3,
	  slidesToScroll: 3,
	  prevArrow: '.arrow-prev-2',
	  nextArrow: '.arrow-next-2',
	  responsive: [
	    {
	      breakpoint: 992,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 2,
	        infinite: true,
	        dots: false
	      }
	    },
	    {
	      breakpoint: 768,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1
	      }
	    }

	  ]
	});

	// Accordion
	$(".accordion-item .title").click(function(){
		$(this).siblings(".content").slideToggle(300);
	});

	// Submenu's


	const mediaQuery = window.matchMedia('(min-width: 992px)')

	if (mediaQuery.matches) {
	  
		$(".dropdown .nav-link").mouseenter(function(){
			$(this).siblings(".dropdown-menu").addClass("dd-active");
		});

		$(".dropdown").mouseleave(function(){
			$(this).children(".dropdown-menu").removeClass("dd-active");
		});

	} else {

		$(".dropdown .dd-arrow").click(function(){
			$(this).siblings(".dropdown-menu").toggleClass("dd-active");
		});

	}

	// Eventbrite

	$(".events-overzicht .more-link").html('Lees meer');
	$(".events-overzicht a").attr('target','_blank');



});